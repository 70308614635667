<template>
    <el-scrollbar class="chapter-wrapper" style="height: 100%" :native="false">
        <div class="chapter-content">
            <div class="line-item lin-item-one" v-for="(chapterItem, chapterIndex) in chapterList">
                <div class="line-name line-name-one text-overflow" v-if="role === 3">章：{{chapterItem.name}}</div>
                <div class="line-name line-name-one text-overflow" v-else-if="role === 4">第{{getNum(chapterIndex + 1)}}章：{{chapterItem.name}}</div>
<!--                <div class="line-name line-name-one text-overflow">第{{chapterNodeCurrent.length > 0 ? getNum(Number(chapterNodeCurrent[0]) + 1) : getNum(Number(chapterRouteIndex) + 1)}}章：{{chapterItem.name}}</div>-->
                <div v-for="(sectionItem, sectionIndex) in chapterItem.children">
                    <div class="line-name line-name-three line-name-three-two"
                         v-if="Number(sectionItem.type) === 3 || Number(sectionItem.type) === 4"
                         @click="playResource(sectionItem, chapterIndex + '|' + sectionIndex)">
                        <i class="iconfont play-icon">&#xe664;</i>
                        <span class="name-text text-overflow">资源{{getNum(sectionIndex + 1)}}：{{sectionItem.name}}</span>
                        <span class="teaching-status classing" v-if="getCurrIndex(chapterIndex + '|' + sectionIndex)">正在教<i class="iconfont">&#xe8c4;</i></span>
                        <span v-else class="teaching-status" :class="getIndexDone(chapterIndex + '|' + sectionIndex) ? 'classed' : ''">
                            <i class="iconfont">&#xe71e;</i>
                        </span>
                    </div>
                    <div class="line" v-else>
                        <div class="line-name line-name-two text-overflow" v-if="role === 3" :class="sectionIndex === Number(chapterNodeCurrent[1]) ? 'current' : ''">节：{{sectionItem.name}}</div>
                        <div class="line-name line-name-two text-overflow" v-else-if="role === 4" :class="sectionIndex === Number(chapterNodeCurrent[1]) ? 'current' : ''">第{{getNum(sectionIndex + 1)}}节：{{sectionItem.name}}</div>
                        <div class="line">
                            <div class="line-name line-name-three" v-for="(resourceItem, resourceIndex) in sectionItem.children"
                                 @click="playResource(resourceItem, chapterIndex + '|' + sectionIndex + '|' + resourceIndex)">
                                <i class="iconfont play-icon">&#xe664;</i>
                                <span class="name-text text-overflow">资源{{resourceIndex + 1}}：{{resourceItem.name}}</span>
                                <span class="teaching-status classing"
                                      v-if="getCurrIndex(chapterIndex + '|' + sectionIndex + '|' + resourceIndex)">
                                    正在教<i class="iconfont">&#xe8c4;</i>
                                </span>
                                <span class="teaching-status" v-else
                                      :class="getIndexDone(chapterIndex + '|' + sectionIndex + '|' + resourceIndex) ? 'classed' : ''">
                                    <i class="iconfont">&#xe71e;</i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        props: {
            chapterList: {
                type: Array,
            },
            chapterNodeCurrent: {
                type: Array
            }
        },
        data() {
            return {
                // chapterRouteIndex: this.$route.query.chapter || '',
                // nodeRouteIndex: this.$route.query.node || '',
                id: Number(this.$route.query.id) || '',
                // chapterList: [],
                courseTitle: '',
                pdfSrc: '',
                videoSrc: '',
                indexCurrent: '',
                indexDone: [],
                role: Number(localStorage.getItem('role')),
            }
        },
        mounted() {
             // console.log('chapterNodeCurrentneiye', this.chapterNodeCurrent)
            if (this.chapterList.length > 0) {
                let sectionSt = this.chapterList[0].children[0];
                if (Number(sectionSt.type) === 3 || 4 === Number(sectionSt.type)) {
                    this.indexCurrent = '0|0';
                } else {
                    this.indexCurrent = '0|0|0';
                }
            }
        },
        methods: {
            // getCourseList(id) {
            //     let params = {
            //         id: id,
            //     }
            //     this.$shttp.axiosGetBy(this.$api.courseContent, params, (res) => {
            //         console.log(res)
            //         if (res.code === 200) {
            //             this.chapterList = res.data.course_content;
            //             this.courseTitle = res.data.course_name;
            //             let sectionSt = this.chapterList[0].children[0];
            //             if (sectionSt.file_name && sectionSt.file_preview_path) {
            //                 this.playResource(sectionSt, '0|0');
            //             } else {
            //                 this.pdfSrc = sectionSt.children[0].file_preview_path;
            //                 this.playResource(sectionSt.children[0], '0|0|0');
            //             }
            //         }
            //     }, (err) => {
            //         console.log(err)
            //     })
            // },
            getNum(num) {
                return this.$common.getNumStr(num);
            },
            getCurrIndex(index) {
                return this.indexCurrent === index;
            },
            getIndexDone(index) {
                let str = this.indexDone.join();
                if (str.indexOf(index) === -1) {
                    return false;
                } else {
                    return true;
                }
            },
            playResource(item, index) {
                this.$emit('getResource', item, index);
                this.indexCurrent = index;
                if (this.indexDone.length) {
                    let str = this.indexDone.join();
                    if (str.indexOf(index) === -1) {
                        this.indexDone.push(index);
                    }
                } else {
                    this.indexDone.push(index);
                }
            },
        }
    }
</script>

<style scoped lang="scss">
.chapter-wrapper {
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .chapter-content {
        color: #999;
        margin: 20px 0 10px;
        .line-item {
            &.lin-item-one {
                margin-bottom: 10px;
            }
            .line-name {
                padding: 0 20px;
                line-height: 36px;
                &.line-name-one {
                    color: #fff;
                }
                &.line-name-two {
                    padding-left: 40px;
                    color: #ccc;
                    &.current {
                       color: #ff0000;
                    }
                }
                &.line-name-three {
                    display: flex;
                    cursor: pointer;
                    padding-left: 60px;
                    .name-text {
                        margin-left: 8px;
                    }
                    .name-text {
                        width: 1%;
                        flex: 1;
                    }
                    .teaching-status {
                        width: 80px;
                        display: flex;
                        justify-content: flex-end;
                        .iconfont {
                            margin-left: 10px;
                        }
                        &.classed {
                            color: #3296FA;
                        }
                        &.classing {
                            color: #ff0000;
                        }
                    }
                }
                &.line-name-three-two {
                    padding-left: 40px;
                }
            }
        }
    }
}
</style>