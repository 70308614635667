<template>
    <div class="quiz-wrapper">
        <el-scrollbar class="quiz-content" :native="false" ref="scrollArea">
            <el-timeline>
                <div v-if="practicalData.length === 0" style="text-align: center;background: #fff; color: #666; margin: 20px 0; padding: 10px">暂无实训</div>
                <el-timeline-item
                        :timestamp="practicalItem.create_time"
                        placement="top"
                        class="quiz-item"
                        v-for="(practicalItem, practicalKey) in practicalData"
                        color="#E10303">
                    <el-card>
                        <div class="item-info">
                            <div class="user-avatar">
                                <img v-if="practicalItem.teacherInfo.t_ico" :src="practicalItem.teacherInfo.t_ico.src" alt="">
                                <img v-else src="../../assets/images/user_img.png" alt="" />
                            </div>
                            <span class="user-name text-overflow">{{practicalItem.teacherInfo.t_name}}</span>
                            <el-button type="primary" size="mini" @click="viewDetail(practicalItem)">进入实训</el-button>
                            <el-button type="danger" size="mini" @click="delProject(practicalItem.id)" v-if="role === 3">删除</el-button>
                        </div>
                        <div class="item-bottom" v-if="practicalItem.residue_time === '实训已结束'">
                            <span class="item-title text-overflow">{{practicalItem.projectInfo.project_title}}</span>
                            <span class="item-time">{{practicalItem.residue_time}}</span>
                        </div>
                        <div class="item-bottom" v-else>
                            <span class="item-title text-overflow">{{practicalItem.projectInfo.project_title}}</span>
                            <span class="item-time">{{practicalItem.residue_time.day}}: </span>
                            <span class="item-time">{{practicalItem.residue_time.hour}}: </span>
                            <span class="item-time">{{practicalItem.residue_time.min}}: </span>
                            <span class="item-time">{{practicalItem.residue_time.sec}}</span>
                        </div>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </el-scrollbar>
        <div class="choose-paper" v-if="role === 3">
            <el-button type="primary" size="medium" @click="choosePractical">发布实训</el-button>
        </div>
        <el-dialog title="发布实训" :visible.sync="choosePracticalDialog" width="30%" @close="resetForm()" :close-on-click-modal="false" :append-to-body="true">
            <el-form :model="publishForm" ref="publishForm" :rules="rules" label-position="left">
                <el-form-item label="选择项目:" prop="resource">
                    <el-radio-group v-model="publishForm.resource">
                        <el-radio label="备课选择"></el-radio>
                        <el-radio label="项目库选择"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="" :label-width="formLabelWidth" prop="projectId" v-if="publishForm.resource === '备课选择'">
                    <el-select v-model="publishForm.projectId" placeholder="请选择项目" clearable style="width: 100%;">
                        <el-option v-for="projectItem in LessonPreparation" :label="projectItem.project_title" :value="projectItem.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="" :label-width="formLabelWidth" prop="projectId" v-if="publishForm.resource === '项目库选择'">
                    <el-select v-model="publishForm.projectId" placeholder="请选择项目" clearable style="width: 100%;">
                        <el-option v-for="projectItem in projectLibrary" :label="projectItem.project_title" :value="projectItem.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="实训时长:" :label-width="formLabelWidth" prop="drill_minute">
                    <el-input-number
                            :precision="0"
                            v-model="publishForm.drill_minute"
                            :controls="false"
                            class="pw-input"
                            :min="1"
                            :max="99999999"
                            style="width: 175px;">
                    </el-input-number>
                    <span style="margin-left: 5px">分钟</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="choosePracticalDialog = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="submitForm('publishForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import _ from "underscore";

    export default {
        name: "Practical",
        props: {
            chapterNodeCurrent: {
                type: Array
            }
        },
        data() {
            return {
                role: Number(localStorage.getItem('role')),
                //实训列表数据
                practicalData: [],
                //备课里的项目列表
                LessonPreparation: [],
                //项目库里的项目列表
                projectLibrary: [],
                //发布实训表单
                publishForm: {
                    course_id: '',
                    projectId: '',
                    resource: '备课选择',
                    drill_minute: '',
                },
                rules: {
                    resource: [
                        { required: true, message: '请选择项目类型', trigger: 'change' }
                    ],
                    projectId: [
                        { required: true, message: '请输选择项目', trigger: 'change' },
                    ],
                    drill_minute: [
                        { required: true, message: '请输入实训时长', trigger: 'blur' },
                    ]
                },
                //班级名称
                className: this.$route.query.className || '',
                //是否显示发布实训弹窗
                choosePracticalDialog: false,
                formLabelWidth: '82px'
            }
        },
        created() {
            this.getPracticalList();
        },
        methods: {
            //发布实训弹窗
            choosePractical() {
                this.choosePracticalDialog = true;
                this.getPracticalList();
                if (this.role === 3) {
                    this.getLessonPreparationList();
                    this.getProjectLibraryList();
                    this.resetForm();
                }
            },
            //获取备课里的项目列表
            getLessonPreparationList() {
                let param = {
                    course_chapter: String(this.chapterNodeCurrent[0]),
                    course_node: String(this.chapterNodeCurrent[1]),
                }
                if (this.role === 3) {
                    if (this.$route.query.id) {
                        param.course_id = this.$route.query.id;
                    }
                    //先查询课程那边自定义/拖拽保存的实训列表
                    this.$shttp.axiosGetBy(this.$api.course_getcoursecustom, param, (res) => {
                        if (res.code === 200) {
                            //没有数据的话就查询总管理员创建课程时拖拽保存的实训列表
                            if (res.data.length === 0) {
                                let params = {
                                    id: Number(this.$route.query.id),
                                    chapter: String(this.chapterNodeCurrent[0]),
                                    node: String(this.chapterNodeCurrent[1])
                                }
                                this.$shttp.axiosGetBy(this.$api.courseinfo, params, (res) => {
                                    if (res.code === 200) {
                                        // this.LessonPreparation = res.data.projects;
                                        let project = res.data.projects;
                                        let courseContent = res.data.course_content.children;
                                        this.LessonPreparation = this.formatProject(project,courseContent);
                                    } else {
                                        this.$message.error(res.msg);
                                    }
                                }, (err) => {
                                    console.log(err);
                                })
                            } else {
                                this.LessonPreparation = res.data.projects;
                            }
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            formatProject(projects,courseContent) {
                let reslist = [];
                //遍历出当前章节的资源
                _.each(courseContent, (item) => {
                    _.each(projects, (item1) => {
                        if (item.type === 5) {
                            if (Number(item.id) === Number(item1.id)) {
                                reslist.push(item1);
                            }
                        }
                    })
                })
                return reslist;
            },
            //获取项目库里的项目列表
            getProjectLibraryList() {
                this.$shttp.axiosGet(this.$api.project_projectListNoPage, (res) => {
                    if (res.code === 200) {
                        this.projectLibrary = res.data.list;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //实训列表
            getPracticalList() {
                let param = {
                    type: 'all'
                }
                if (Number(this.$route.query.classId)) {
                    param.c_id = Number(this.$route.query.classId);
                }
                // if (this.role === 3) {
                //     if (Number(this.$route.query.id)) {
                //         param.course_id = Number(this.$route.query.id);
                //     }
                // } else if (this.role === 4) {
                //     if (Number(this.$route.query.tCourseId)) {
                //         param.course_id = Number(this.$route.query.tCourseId);
                //     }
                // }
                this.$shttp.axiosGetBy(this.$api.drill_showDrillList, param, (res) => {
                    if (res.code === 200) {
                        this.practicalData = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //查看实训详情
            viewDetail(item) {
                let routeUrl = this.$router.resolve({
                    path: '/course/practical/detail',
                    query: {
                        p_id: item.project_id,
                        sx_id: item.id,
                        className: this.className
                    }
                })
                window.open(routeUrl .href, '_blank');
            },
            //删除项目
            delProject(id) {
                this.$confirm('是否删除该实训，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$shttp.axiosGetBy(this.$api.drill_delDrill, {id: Number(id)}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getPracticalList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            //发布实训
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('course_id', this.$route.query.id);
                        formData.append('course_chapter', this.$route.query.chapter);
                        formData.append('course_record_id', this.$route.query.recordId);
                        formData.append('project_id', this.publishForm.projectId);
                        formData.append('c_id', Number(this.$route.query.classId));
                        formData.append('drill_minute', this.publishForm.drill_minute);

                        this.$shttp.axiosPost(this.$api.drill_issuedDrill, formData, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.msg);
                                this.getPracticalList();
                                this.choosePracticalDialog = false;
                                //实时发布实训
                                let data = {
                                    send_role: 3,
                                    send_ico: localStorage.getItem('sIcon') || require('../../assets/images/user_img.png'),
                                    sx_id: res.data.id,
                                    action: 'sx'
                                };
                                //子组件调用父组件
                                this.$emit('sendPra',data);
                            } else {
                                this.$message({
                                    type: 'warning',
                                    duration: 1000,
                                    message: res.msg,
                                })
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //重置
            resetForm() {
                this.publishForm = {
                    course_id: '',
                    projectId: '',
                    resource: '备课选择',
                    drill_minute: '',
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .quiz-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        .quiz-content {
            height: 100%;
            flex: 1;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .el-timeline {
                padding: 20px;
                .quiz-item {
                    &:last-child {
                        padding-bottom: 0;
                    }
                    ::v-deep .el-timeline-item__tail {
                        border-color: #E10303;
                    }
                    .el-card {
                        margin-bottom: 16px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        ::v-deep .el-card__body {
                            padding: 16px;
                        }
                    }
                    .item-info {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .user-avatar {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;
                            border: 1px solid #ddd;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .user-name {
                            width: 1%;
                            flex: 1;
                            margin: 0 10px;
                        }
                    }
                    .item-bottom {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .item-title {
                            width: 1%;
                            flex: 1;
                        }
                        .item-time {
                            color: #999;
                        }
                        .item-status {
                            margin: 0 10px;
                            .red {
                                color: #ff0000;
                            }
                            .blue {
                                color: #409eff;
                            }
                        }
                    }
                }
            }
        }
        .choose-paper {
            padding: 10px 20px;
            border-top: 1px solid #ddd;
            background: #fff;
            text-align: center;
        }
    }
    .publish-project-form {
        .select-project {
            ::v-deep .el-form-item__content {
                margin-left: 73px !important;
                .el-select {
                    width: 78% !important;
                }
            }
        }
    }

</style>