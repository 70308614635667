<template>
    <div class="discuss-content">
        <el-scrollbar style="flex: 1" :native="false" class="discuss-details" ref="scrollArea">
            <ul class="discuss-list" id="discussList" ref="scroll">
                <li v-for="item in data">
                    <div v-if="item.type === 1" class="message-enter">
                        <span class="time">{{item.send_time}}</span>
                        <span class="message">{{item.message}}</span>
                    </div>
                    <div v-if="role === 3">
                        <div v-if="item.type === 2 && item.send_id != teacher_id">
                            <div class="user">
                                <img :src="item.ico" class="user-avatar" alt="">
                                <span class="teacher-sign" v-if="item.send_role === 3">老师</span>
                                <span class="teacher-name">{{item.name}}</span>
                                <span class="teacher-time">{{item.send_time}}</span>
                            </div>
                            <span class="content" v-html="item.content"></span>
                        </div>
                        <div v-if="item.type === 2 && item.send_id === teacher_id">
                            <div class="user" style="justify-content: flex-end;">
                                <span class="teacher-time" style="margin-right: 10px;">{{item.send_time}}</span>
                                <span class="teacher-sign" v-if="item.send_role === 3">老师</span>
                                <span class="teacher-name">{{item.name}}</span>
                                <img :src="item.ico" class="user-avatar" alt="" style="margin-right: 0">
                            </div>
                            <span class="content" v-html="item.content" style="text-align: right;"></span>
                        </div>
                    </div>
                    <div v-if="role === 4">
                        <div v-if="item.type === 2 && item.send_id != student_id">
                            <div class="user">
                                <img :src="item.ico" class="user-avatar" alt="">
                                <span class="teacher-sign" v-if="item.send_role === 3">老师</span>
                                <span class="teacher-name">{{item.name}}</span>
                                <span class="teacher-time">{{item.send_time}}</span>
                            </div>
                            <span class="content" v-html="item.content"></span>
                        </div>
                        <div v-if="item.type === 2 && item.send_id === student_id">
                            <div class="user" style="justify-content: flex-end;">
                                <span class="teacher-time" style="margin-right: 10px;">{{item.send_time}}</span>
                                <span class="teacher-sign" v-if="item.send_role === 3">老师</span>
                                <span class="teacher-name">{{item.name}}</span>
                                <img :src="item.ico" class="user-avatar" alt="" style="margin-right: 0">
                            </div>
                            <span class="content" v-html="item.content" style="text-align: right;"></span>
                        </div>
                    </div>
                </li>
            </ul>
        </el-scrollbar>
        <div class="discuss-send">
            <div id="editor-discuss"></div>
            <el-button type="primary" @click="onSubmit">发送</el-button>
        </div>
    </div>
</template>

<script>
    import wsc from "../../utils/wsConfig";
    import WE from "../../utils/wEditor";

    export default {
        name: "Discuss",
        data() {
            return {
                role: Number(localStorage.getItem('role')) || '',
                user_name: localStorage.getItem('sUsername') || '',
                teacher_id: Number(localStorage.getItem('sId')) || '',
                student_id: Number(localStorage.getItem('s_id')) || '',
                send_id: 0,
                editorText: null,
                ws: null
            }
        },
        props: ['data'],
        created() {
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].ico === null) {
                    this.data[i].ico = require('../../assets/images/user_img.png');
                }
            }
        },
        mounted() {
            if (this.role === 3) {
                this.send_id = this.teacher_id;
            } else if (this.role === 4) {
                this.send_id = this.student_id;
            }

            this.editorText = new WE('#editor-discuss', this.$api.uploadKindeditor);

            let ele = document.querySelector('.w-e-text');
            ele.addEventListener('keydown', (evt) => {
                if (evt.keyCode === 13 && evt.altKey === false && evt.metaKey === false) {
                    evt.cancelBubble=true;
                    evt.preventDefault();
                    evt.stopPropagation();
                    this.onSubmit();
                } else if ((evt.keyCode === 13 && evt.metaKey === true) || (evt.keyCode === 13 && evt.altKey === true)) {
                    let text = this.editorText.getWEHtml();
                    text += '<p><br></p>';
                    this.editorText.setWEHtml(text);
                }
            })
        },
        methods: {
            toBottom() {
                this.$refs['scrollArea'].wrap.scrollTop = 9999999;
            },
            //绑定客户端
            bindClient(client_id) {
                let param = {
                    clientID: client_id
                }
                this.$shttp.axiosGetBy(this.$api.bindClient, param, (res) => {

                }, (err) => {
                    console.log(err)
                });
            },
            onSubmit() {
                if (this.editorText.getWEHtml() === '') {
                    this.$message('发送内容不能为空！'); return
                } else {
                    if (wsc.type === 1) {
                        let data = {
                            message: this.editorText.getWEHtml(),
                            send_id: this.send_id,
                            send_role: this.role,
                            send_ico: localStorage.getItem('sIcon') || require('../../assets/images/user_img.png'),
                            send_name: this.user_name,
                            course_record_id: Number(this.$route.query.recordId) || '',
                            c_id: Number(this.$route.query.classId) || '',
                            action: 'message'
                        };
                        //子组件调用父组件
                        this.$emit('sendMesg',data);
                    } else {
                        let formData = new FormData();
                        formData.append('msg', this.editorText.getWEHtml());
                        formData.append('course_record_id', Number(this.$route.query.recordId) || '');
                        this.$shttp.aPost(this.$api.sendMessage, formData, (res) => {

                        }, (err) => {
                            console.log(err);
                        })
                    }
                }
                //发送完消息，清空文本域内容
                this.editorText.getNull();
            }
        }
    }
</script>

<style scoped lang="scss">
    .discuss-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        .discuss-details {
            height: calc(100% - 220px);
            ::v-deep .el-scrollbar__wrap {
                margin-right: -16px;
                overflow-x: hidden;
            }
        }
        .discuss-list {
            /*height: 100%;*/
            margin-top: 0;
            padding: 0 28px;
            list-style: none;
            margin-bottom: 0;
            li {
                margin-bottom: 10px;
                .user {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    margin: 10px 0;
                    .user-avatar {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    .teacher-sign {
                        background-color: #3295F9;
                        color: #fff;
                        width: 40px;
                        text-align: center;
                        margin-right: 10px;
                    }
                    .teacher-name {
                        color: #3295F9;
                        margin-right: 10px;
                        max-width: 190px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .teacher-time {
                        color: #b7b4b4;
                        font-size: 12px;
                    }
                }
                .content {
                    margin: 8px 0;
                    display: block;
                    ::v-deep img {
                        max-width: 100%;
                    }
                }
                .message-enter {
                    display: flex;
                    flex-direction: column;
                    .time {
                        text-align: center;
                        color: #b7b4b4;
                        font-size: 12px;
                    }
                    .message {
                        display: block;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        background-color: #f9f8f8;
                        color: #949191;
                        margin-top: 10px;
                    }
                }
            }
        }
        .discuss-send {
            height: 220px;
            ::v-deep .ke-content {
                padding: 10px;
            }
            ::v-deep .ke-container {
                border-bottom: none;
                .ke-statusbar {
                    display: none;
                }
            }
            #editor-discuss {
                height: calc(100% - 70px);
                ::v-deep .w-e-text-container {
                    border: none !important;
                    height: 120px !important;
                    .w-e-text {
                        box-sizing: initial;
                    }
                    .w-e-panel-tab-content {
                        height: 142px !important;
                    }
                }
            }
            button {
                margin: 15px;
                float: right;
            }
        }
    }
</style>